import service from '@/utils/request';
export var createUserDealerGroup = function createUserDealerGroup(data) {
  return service({
    url: "/userDealerGroup/createUserDealerGroup",
    method: 'post',
    data: data
  });
};
export var updateUserDealerGroup = function updateUserDealerGroup(data) {
  return service({
    url: "/userDealerGroup/updateUserDealerGroup",
    method: 'put',
    data: data
  });
};
export var getUserDealerGroup = function getUserDealerGroup(data) {
  return service({
    url: "/userDealerGroup/getUserDealerGroup",
    method: 'post',
    data: data
  });
};

//getUserDealer
export var getUserDealer = function getUserDealer(data) {
  return service({
    url: "/userDealerGroup/getUserDealer",
    method: 'post',
    data: data
  });
};
export var deleteUserDealerGroup = function deleteUserDealerGroup(data) {
  return service({
    url: "/userDealerGroup/deleteUserDealerGroup",
    method: "delete",
    data: data
  });
};
export var deleteUserDealerGroupByIds = function deleteUserDealerGroupByIds(data) {
  return service({
    url: "/userDealerGroup/deleteUserDealerGroupByIds",
    method: 'delete',
    data: data
  });
};